import GATSBY_COMPILED_MDX from "/home/runner/work/anvil/anvil/packages/design-system/src/components/ActionMenu/ActionMenu.docs.mdx";
import React from 'react';
import * as runtime from 'react/jsx-runtime';
import {compileSync, runSync} from '@mdx-js/mdx';
import {graphql, Link as GatsbyLink} from 'gatsby';
import {BodyText, Headline, Eyebrow, Page, Layout, Stack, TabGroup, Button, Tooltip, Link} from '@servicetitan/design-system';
import {tokens} from '@servicetitan/tokens/core';
import SimpleIcons from 'simple-icons-react-component';
import {GlobalWrapper, SEO, Sidebar, TableOfContents, Tags} from '../components';
import {ScreenSizeContext} from '../context/ScreenSizeContext';
const getIconName = key => {
  switch (key) {
    case 'figma':
      return "Figma";
    case 'github':
      return "GitHub";
    case 'storybook':
      return "Storybook";
  }
};
const createUrl = (key, url) => {
  if (typeof url !== 'string') return null;
  if (url.startsWith('http')) return url;
  switch (key) {
    case 'github':
      return `https://github.com/servicetitan/anvil/tree/master/packages/design-system/src/components/${url}`;
    case 'figma':
      return `https://www.figma.com/file/KLWxYdHAdFNvmt4WjEbCid/?node-id=${url}`;
    case 'storybook':
      return `https://anvil.st.dev/?path=/story/components-${url}`;
  }
};
const MDXRender = mdx => {
  const code = compileSync(mdx, {
    outputFormat: 'function-body',
    development: false
  });
  const {default: Component} = runSync(code, runtime);
  return React.createElement(Component);
};
const PageHeader = ({category, description, title, links, hasTabs, tags}) => {
  const {size} = React.useContext(ScreenSizeContext);
  return React.createElement(Layout, {
    type: "support",
    direction: "right",
    className: "DocsPageHeader"
  }, React.createElement(Layout.Section, null, category && React.createElement(Eyebrow, {
    className: "DocsPageHeader__category"
  }, category), React.createElement(Headline, {
    size: "large",
    className: "DocsPageHeader__title m-b-2"
  }, title, !hasTabs && React.createElement(Tags, {
    tags: tags
  })), description && React.createElement(BodyText, {
    el: "div",
    style: {
      maxWidth: '40em'
    }
  }, MDXRender(description))), links && React.createElement(Layout.Section, null, React.createElement(Stack.Item, {
    alignSelf: "flex-end",
    className: "DocsPageHeader__links",
    style: {
      marginTop: category ? size.smallerThan.desktop ? 0 : 24 : 4,
      marginBottom: size.smallerThan.desktop && 16
    }
  }, React.createElement(Stack, {
    spacing: 1
  }, Object.entries(links).map(IconLink)))));
};
const IconLink = (link, index) => {
  const key = link[0];
  const url = createUrl(key, link[1]);
  const iconName = getIconName(key);
  if (url === null) return null;
  return React.createElement(Tooltip, {
    key: index,
    text: iconName
  }, React.createElement(Stack.Item, null, React.createElement(Link, {
    href: url,
    rel: "noopener noreferrer",
    target: "_blank"
  }, React.createElement(Button, {
    fill: "subtle",
    icon: React.createElement(SimpleIcons, {
      name: iconName,
      color: tokens.colorBlack
    })
  }))));
};
const Tabs = ({show, tabs, tabOrder}) => {
  if (!show) return null;
  return React.createElement(TabGroup, {
    className: "DocsPage__tabs"
  }, tabs.filter(e => !e.node.frontmatter.hidden).sort((a, b) => tabOrder.indexOf(a.node.frontmatter.title) - tabOrder.indexOf(b.node.frontmatter.title)).map(Tab));
};
const Tab = (tab, index) => {
  const tabPath = tab.node.fields.path;
  const tabTitle = tab.node.frontmatter.title;
  const tabTags = tab.node.frontmatter.tags;
  return React.createElement(GatsbyLink, {
    className: "Tab tt-capitalize",
    to: `${tabPath}`,
    key: index,
    activeClassName: "Tab--active"
  }, tabTitle, React.createElement(Tags, {
    tags: tabTags
  }));
};
const PageTemplate = ({data, children}) => {
  if (!data || !data.index) return children;
  const page = data.page;
  const hasTabs = data.index.edges.filter(e => !e.node.frontmatter.hidden).length > 1 ? true : false;
  const frontmatter = hasTabs ? data.index.edges.find(e => e.node.fields.isIndex).node.frontmatter : data.page.frontmatter;
  const title = frontmatter.component || frontmatter.title;
  const rawDescription = frontmatter.rawDescription;
  const links = frontmatter.links;
  const {size} = React.useContext(ScreenSizeContext);
  const showTOC = typeof page.tableOfContents.items !== 'undefined' && page.tableOfContents.items.length > 0;
  return React.createElement(GlobalWrapper, null, React.createElement(SEO, {
    title: title
  }), React.createElement(Page, {
    header: React.createElement(PageHeader, {
      title: title,
      category: page.fields.category,
      description: rawDescription,
      links: links,
      tags: frontmatter.tags,
      hasTabs: hasTabs
    }),
    className: "DocsPage",
    sidebar: React.createElement(Sidebar, {
      localStorageKey: "page-template",
      current: page
    })
  }, React.createElement(Tabs, {
    show: hasTabs,
    tabs: data.index.edges,
    tabOrder: frontmatter.tabs
  }), React.createElement(Layout, {
    type: "support",
    direction: "right",
    className: "DocsPage__layout"
  }, React.createElement(Layout.Section, null, children), showTOC && !size.smallerThan.desktop && React.createElement(Layout.Section, {
    className: "TOC__wrapper",
    style: {
      top: hasTabs ? 32 : 0
    }
  }, React.createElement(TableOfContents, {
    headings: page.tableOfContents.items,
    parent: frontmatter.title,
    path: page.fields.path
  })))));
};
export const pageQuery = graphql`
	query PageQuery($id: String, $parent: String) {
		page: mdx(id: { eq: $id }) {
			id
			frontmatter {
				hidden
				title
				component
				rawDescription
				tags
				links {
					github
					figma
					storybook
				}
			}
			fields {
				path
				globalNav
				category
			}
			tableOfContents
			rawBody
		}
		index: allMdx(filter: {frontmatter: {component: {eq: $parent, ne: null}}}) {
			edges {
				node {
					fields {
						globalNav
						category
						isIndex
						path
					}
					frontmatter {
						title
						component
						rawDescription
						tabs
						tags
						hidden
						links {
							figma
							github
							storybook
						}
					}
				}
			}
		}
	}
`;
PageTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PageTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
